export const DETAILS = {
  name: " کالای برق ایران نور",
  footerName: "ایران نور",
  description: "",
  about : "ایران نور یک سایت معتبر و قابل اعتماد است که در زمینه عرضه انواع لوازم و تجهیزات الکتریکی، صنعتی و دکوراتیو فعالیت می‌کند. این سایت مجموعه‌ای از محصولات متنوع را در اختیار مشتریان خود قرار می‌دهد و خدمات متنوعی را ارائه می‌کند.\n" +
      "\n" +
      "در ایران نور می‌توانید انواع لوازم الکتریکی مورد نیاز خود را پیدا کنید. با تنوع محصولات و برندهای معتبر، شما می‌توانید بر اساس نیازهای خود محصولات را انتخاب کنید.\n" +
      "\n" +
      "علاوه بر لوازم الکتریکی، ایران نور همچنین در زمینه لوازم و تجهیزات صنعتی فعالیت می‌ کند.\n" +
      "\n" +
      "به علاوه، ایران نور در زمینه انجام کلیه امور برق تجاری، صنعتی و ساختمانی نیز فعالیت می‌کند. تیم متخصص و مجرب ایران نور آماده است تا به شما در خدمات نصب و راه‌ اندازی سیستم‌ های برقی، تعمیرات و نگهداری، طراحی و برنامه‌ ریزی پروژه‌ های برقی و سایر خدمات مرتبط کمک کند.\n" +
      "\n" +
      "ایران نور یک سایت معتبر است که محصولات متنوع و خدمات متنوع در زمینه لوازم و تجهیزات الکتریکی، صنعتی و دکوراتیو را به مشتریان ارائه می‌دهد. با توجه به تنوع و کیفیت محصولات، تجربه و تخصص تیم ایران نور، می‌ توانید به راحتی نیازهای خود را برآورده کنید و از خدمات حرفه ‌ای در زمینه برق تجاری، صنعتی و ساختمانی بهره‌ مند شوید.\n" +
      "\n",
  shortAbout: "ایران نور یک سایت معتبر و قابل اعتماد است که در زمینه عرضه انواع لوازم و تجهیزات الکتریکی، صنعتی و دکوراتیو فعالیت می‌کند. این سایت مجموعه‌ای از محصولات متنوع را در اختیار مشتریان خود قرار می‌دهد و خدمات متنوعی را ارائه می‌کند.\n" +
      "\n" +
      "در ایران نور می‌توانید انواع لوازم الکتریکی مورد نیاز خود را پیدا کنید. با تنوع محصولات و برندهای معتبر، شما می‌توانید بر اساس نیازهای خود محصولات را انتخاب کنید.\n" +
      "\n" +
      "علاوه بر لوازم الکتریکی، ایران نور همچنین در زمینه لوازم و تجهیزات صنعتی فعالیت می‌ کند.\n" +
      "\n" +
      "به علاوه، ایران نور در زمینه انجام کلیه امور برق تجاری، صنعتی و ساختمانی نیز فعالیت می‌کند. تیم متخصص و مجرب ایران نور آماده است تا به شما در خدمات نصب و راه‌ اندازی سیستم‌ های برقی، تعمیرات و نگهداری، طراحی و برنامه‌ ریزی پروژه‌ های برقی و سایر خدمات مرتبط کمک کند.",
  link: "https://Irannoorelectric.ir",
  phone: ["09122618928",
    "02634497210"],
  email: [],
  postalCode: [],
  address: ["کرج، رجایی شهر ،خیابان مطهری ، نرسیده به پل روحانی ، نبش کوچه ولیعصر 2"],
  supportDescription: "",
  workingHours: ["شنبه تا پنجشنبه 10 تا 13 و 16:30 تا 21", "جمعه 17 تا 21"],
  nemadLink:"https://trustseal.enamad.ir/?id=472046&Code=X2uXpdzIvAJvIi66u6XgaQhWSTdJJnay",
  nemadImage:"https://trustseal.enamad.ir/logo.aspx?id=472046&Code=X2uXpdzIvAJvIi66u6XgaQhWSTdJJnay",
  nemadCode:"X2uXpdzIvAJvIi66u6XgaQhWSTdJJnay",
  mapIframeSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3234.289489308488!2d50.980427597208774!3d35.84190449510082!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f8dbf1a8abc422f%3A0xb05899d4db169f98!2z2qnYp9mE2KfbjCDYqNix2YIg2KfbjNix2KfZhiDZhtmI2LE!5e0!3m2!1sfa!2s!4v1709984703153!5m2!1sfa!2s",
  socialMedia: [
    // {
    //   title: 'واتس اپ',
    //   value: '09105894283',
    //   name: 'whatsapp',
    //   link: 'https://api.whatsapp.com/send/?phone=09105894283&text&app_absent=0',
    //   icon: <i className="lab la-whatsapp"/>,
    //   isPrimary: true
    // },
    /*{
        title: 'تلگرام',
        value: '09357195494',
        name: 'telegram',
        link: 'https://t.me/09357195494',
        icon: <i className="lab la-telegram-plane"/>,
        isPrimary: true
    },*/
    // {
    //   title: 'اینستاگرام',
    //   value: 'armitabeauty',
    //   name: 'instagram',
    //   link: "https://instagram.com/armitabeauty",
    //   icon: <i className="lab la-instagram"/>,
    //   isPrimary: true
    // },
    /*{
                title: 'ایتا',
                value: '09357195494',
                name: 'eitaa',
                link: 'https://eitaa.com/09357195494',
                icon: <img src={eitaaIcon} alt='ایتا'/>,
                isPrimary: false
            }
    {
        title: 'ایمیل',
        value: 'Volvotak@gmail.com',
        name: 'email',
        link: 'mailto:Volvotak@gmail.com',
        icon: <i className="las la-envelope"></i>,
        isPrimary: true
    },
    {
        title: 'تس',
        value: '09133377432',
        name: 'phone',
        link: 'tel:989133377432',
        icon: <PhoneCall/>,
        isPrimary: true
    },
            /*{
                title: 'آپارات',
                value: 'Alo_aghsat',
                name: 'aparat',
                link: 'https://www.aparat.com/Alo_aghsat',
                icon: <SiAparat size={25}/>,
                isPrimary: true
            },
            {
                title: 'تماس',
                value: '02636634893',
                name: 'phone',
                link: 'tel:+982636634893',
                icon: <PhoneCall/>,
                isPrimary: false
            },
            {
                title: 'سروش پلاس',
                value: '09331074475',
                name: 'soroushPlus',
                link: '',
                icon: <img src={soroushIcon} alt='سروش'/>,
                isPrimary: false
            },
            {
                title: 'سروش پلاس',
                value: '09332094475',
                name: 'soroushPlus',
                link: '',
                icon: <img src={soroushIcon} alt='سروش'/>,
                isPrimary: false
            },
            {
                title: 'سروش پلاس',
                value: '09330324475',
                name: 'soroushPlus',
                link: '',
                icon: <img src={soroushIcon} alt='سروش'/>,
                isPrimary: false
            },
            {
                title: 'ایتا',
                value: '09331074475',
                name: 'eitaa',
                link: '',
                icon: <img src={eitaaIcon} alt='ایتا'/>,
                isPrimary: false
            },*/
  ],
  services: [],
  brands: [
    /*{
        name: 'Apple',
        image: <img src={appleImage} alt='apple'/>,
    },*/
  ],
  "enamadLink": "",
  "samandehiLink": "",
  "ZarinpalLink": "https://www.zarinpal.com/webservice/TrustCode"
}